<template>
  <section id="orders-wrapper">
    <Card class="dashboard-orders-list" :class="isLocaleLTR && 'ltr-dashboard-orders-list'" dis-hover>
      <template slot="title">
        <div class="d-flex align-center justify-between">
          <div class="custom-tabs">
            <span
                :class="{ active: tabName === 'current' }"
                class="font-Bold-14"
                @click="changeTab('current')"
            >
              {{ $t('dashboard.openOrders') }}
            </span>
            <span
                :class="{ active: tabName === 'stopLimit' }"
                class="font-Bold-14"
                @click="changeTab('stopLimit')"
            >
              {{ $t('dashboard.stopLimitOrders') }}
            </span>
          </div>

          <router-link
              :to="tabName === 'current' ? '/orders-history' : '/precondition-orders-history'"
              :class="isLocaleLTR? 'me-3': 'ms-3'"
              size="small"
              type="text"
          >
            <BaseIcon name="History"/>
          </router-link>
        </div>
      </template>

      <Card
          v-if="(tabName === 'current' && (openOrdersLoading || !openOrdersList.length)) || (tabName === 'stopLimit' && (preconditionsLoading || !preconditionsList.length))"
          class="loading-wrapper d-flex align-center justify-center"
          dis-hover
      >
        <Loader v-if="openOrdersLoading || preconditionsLoading"/>

        <template v-else-if="!openOrdersList.length || !preconditionsList.length">
          <BaseIcon class="no-data-icon-size" name="NoData"/>
          <div class="font-Regular-16 nodata-color mb-4">
            {{ $t('dashboard.noOrders') }}
          </div>
          <Button class="text-black border-radius-8" to="/markets" type="warning">
            <div class="d-flex align-center">
              <BaseIcon class="me-1" name="GrowLine"/>
              <span class="font-Medium-12">
              {{ $t("dashboard.markets") }}
            </span>
            </div>
          </Button>
        </template>
      </Card>

      <template v-if="tabName === 'current'">
        <Card v-for="(order, index) in openOrdersList" :key="index" class="order-card " dis-hover>
          <Row>
            <Col style="text-align: start" :sm="12" :span="24">
              <div class="font-Regular-12 gray-text mb-1">
                {{ $t('dashboard.pair') }}
              </div>
              <div class="font-Regular-16 mb-2">
                {{ order.symbol }}
              </div>

              <div class="font-Regular-12 gray-text mb-1">
                {{ $t('dashboard.price') }}
              </div>

              <div class="font-Regular-16 mb-2">
                {{ order.price | numberWithCommas(getPairCoinScales[order.symbol].frontBaseCoinScale) }}
              </div>

              <Button v-if="!isSmallScreen" class="cancel-btn" size="small" type="text"
                      @click="cancelOrder(order.orderId)">
                <span class="font-Regular-12">
                  {{ $t('dashboard.cancelOrder') }}
                </span>
              </Button>
            </Col>

            <Col class="text-start" :sm="12" :span="24">
              <div class="font-Regular-12 gray-text mb-1 text-start">
                {{ $t('dashboard.orderType') }}
              </div>

              <div class="mb-2 text-start">
                <span class="font-Regular-16 me-3">
                  <template v-if="order.source === 'STOP_LIMIT_ORDER' || order.source === 'STOP_LIMIT_CONDITION'">
                    {{ $t("dashboard.stopLimit") }}
                  </template>
                  <template v-else-if="order.type === 'LIMIT_PRICE' && order.source === 'MANUAL_ORDER'">
                    {{ $t("dashboard.limitOrder") }}
                  </template>
                  <template v-else-if="order.type === 'MARKET_PRICE' && order.source === 'MANUAL_ORDER'">
                    {{ $t("dashboard.marketOrder") }}
                  </template>
                  <template v-else-if="order.source === 'OCO_ORDER'">
                    {{ $t("dashboard.ocoLimit") }}
                  </template>
                  <template v-else-if="order.source === 'OCO_STOP_LIMIT_ORDER' || order.source === 'OCO_CONDITION'">
                    {{ $t("dashboard.ocoStopLimit") }}
                  </template>
                </span>

                <span :class="order.direction === 'BUY' ? 'buy-bg' : 'sell-bg'" class="font-Regular-12 direction-chip">
                  {{ order.direction === "BUY" ? $t('dashboard.buy') : $t('dashboard.sell') }}
                </span>
              </div>

              <div class="font-Regular-12 gray-text mb-1 text-start">
                {{ $t('dashboard.orderDate') }}
              </div>

              <div class="font-Regular-16 mb-4 text-start">
                {{ order.time | dateFormat }}
              </div>

              <Button
                  v-if="!isSmallScreen"
                  :loading="detailsBtnLoading === order.orderId"
                  class="details-btn"
                  size="small"
                  type="text"
                  @click="getDetails(order)"
              >
                <span class="font-Regular-14">
                  {{ $t('dashboard.details') }}
                </span>
                <Icon type="ios-arrow-back" :class="[isLocaleLTR ? 'rotate-180' : '']"/>
              </Button>
            </Col>
          </Row>
          <div class="d-flex flex-row-reverse justify-between" v-if="isSmallScreen">
            <Button
                :loading="detailsBtnLoading === order.orderId"
                class="details-btn"
                size="small"
                type="text"
                @click="getDetails(order)"
            >
              <span class="font-Regular-14">
                {{ $t('dashboard.details') }}
              </span>
              <Icon type="ios-arrow-back"/>
            </Button>
            <Button class="cancel-btn" size="small" type="text"
                    @click="cancelPrecondition(order.orderPreconditionId)">
              <span class="font-Regular-12">
                {{ $t('dashboard.cancelOrder') }}
              </span>
            </Button>
          </div>
        </Card>
      </template>

      <template v-else-if="tabName === 'stopLimit'">
        <Card v-for="(order, index) in preconditionsList" :key="index"
              class="order-card" dis-hover>
          <Row>
            <Col style="order: 1;text-align: start" :sm="12" :span="24" class="text-start">
              <div class="font-Regular-12 gray-text mb-1">
                {{ $t('dashboard.pair') }}
              </div>
              <div class="font-Regular-16 mb-2">
                {{ order.symbol }}
              </div>

              <div class="font-Regular-12 gray-text mb-1">
                {{ $t('dashboard.price') }}
              </div>

              <div class="font-Regular-16 mb-4">
                {{ order.strOrderPrice | numberWithCommas(getPairCoinScales[order.symbol].frontBaseCoinScale) }}
              </div>

              <Button v-if="!isSmallScreen" class="cancel-btn" size="small" type="text"
                      @click="cancelPrecondition(order.orderPreconditionId)">
                <span class="font-Regular-12">
                  {{ $t('dashboard.cancelOrder') }}
                </span>
              </Button>
            </Col>

            <Col :sm="12" :span="24">
              <div class="font-Regular-12 gray-text mb-1 text-start">
                {{ $t('dashboard.orderType') }}
              </div>

              <div class="mb-2 text-start">
                <span>
                  <span class="font-Regular-16 me-3" v-if="order.source === 'STOP_LIMIT_CONDITION'">
                    {{ $t("dashboard.stopLimit") }}
                  </span>
                  <span class="font-Regular-16 me-3" v-else-if="order.source === 'OCO_CONDITION'">
                    {{ $t("dashboard.ocoStopLimit") }}
                  </span>
                </span>

                <span :class="order.direction === 'BUY' ? 'buy-bg' : 'sell-bg'" class="font-Regular-12 direction-chip">
                  {{ order.direction === "BUY" ? $t('dashboard.buy') : $t('dashboard.sell') }}
                </span>
              </div>

              <div class="font-Regular-12 gray-text text-start mb-1">
                {{ $t('dashboard.activationPrice') }}
              </div>

              <div class="font-Regular-16 mb-2 text-start">
                {{ order.strActivationPrice | numberWithCommas(getPairCoinScales[order.symbol].frontBaseCoinScale) }}
                <span class="font-Regular-16">
                  {{ order.momentMarketPrice >= order.activationPrice ? "=>" : "=<" }}
                </span>
              </div>

              <div class="font-Regular-12 gray-text mb-1 text-start">
                {{ $t('dashboard.orderDate') }}
              </div>

              <div class="font-Regular-16 mb-2 text-start">
                {{ order.createdDate | dateFormat }}
              </div>
            </Col>
          </Row>
          <div class="d-flex justify-center" v-if="isSmallScreen">
            <Button :class="['cancel-btn', isSmallScreen && 'fill-width']" size="small" type="text"
                    @click="cancelPrecondition(order.orderPreconditionId)">
              <span class="font-Regular-12">
                {{ $t('dashboard.cancelOrder') }}
              </span>
            </Button>
          </div>
        </Card>
      </template>
    </Card>

    <Modal
        v-model="cancelOrderModal"
        :class-name="isLocaleLTR ? 'custom-modal ltr':'custom-modal rtl'"
        width="400"
    >
      <div slot="header" class="custom-modal-header font-Regular-16 text-center">
        {{ $t('exchange.cancelOrder') }}
      </div>
      <div class="font-Regular-14 custom-modal-body text-start" :style="isLocaleLTR?'padding-inline:15px':''">
        <p>{{ $t('exchange.cancelOrderConfirmation') }}</p>
      </div>
      <div slot="footer" class="custom-modal-footer">
        <Button class="font-Medium-14" type="warning" @click="cancel">
          {{ $t('exchange.confirm') }}
        </Button>
        <Button class="font-Medium-14 ms-2" @click="cancelOrderModal = false">
          {{ $t('exchange.cancel') }}
        </Button>
      </div>
    </Modal>

    <Modal
        v-model="orderDetailsModal"
        :class-name="isLocaleLTR ?'custom-modal ltr':'custom-modal rtl'"
        footer-hide
    >
      <div slot="header" class="custom-modal-header">
        <span class="font-Regular-16">{{ $t('openOrders.orderDetails') }}</span>
      </div>
      <div class="font-Regular-14 custom-modal-body pb-5">
        <ul v-if="orderDetails && orderDetails.order && Object.entries(orderDetails.order).length > 0"
            class="preconditionDetailList">
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("openOrders.time") }}</span>
            <span class="font-Regular-12">{{ orderDetails.order.createdDate | dateFormat }}</span>
          </li>

          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("openOrders.type") }}</span>
            <span class="font-Regular-12">
                        <template v-if="orderDetails.order.source === 'OCO_ORDER'">
                            {{ $t("openOrders.ocoLimit") }}
                        </template>

                        <template v-else-if="orderDetails.order.source === 'MANUAL_ORDER'">
                            {{ $t("openOrders.limit") }}
                        </template>
                    </span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("openOrders.pairCoin") }}</span>
            <span class="font-Regular-12">{{ orderDetails.order.symbol }}</span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("openOrders.price") }}</span>
            <span class="font-Regular-12">{{ numberWithCommas(orderDetails.order.orderPrice) }}</span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("openOrders.amount") }}</span>
            <span class="font-Regular-12">{{ orderDetails.order.amount }} {{
                orderDetails.order.symbol.toUpperCase().split("/")[0]
              }}</span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("openOrders.turnOver") }}</span>
            <span class="font-Regular-12">{{ Number(orderDetails.order.turnOver).toFixed() }} {{
                orderDetails.order.symbol.toUpperCase().split("/")[1]
              }}</span>
          </li>
        </ul>

        <div class="table-responsive-wrapper">
          <Table :no-data-text="loading ? '' : $t('common.nodata')"
                 :columns="orderDetailColumns" :data="orderDetails.detail" class="table-responsive-wrapper-in"
                 max-height="250"></Table>
        </div>
      </div>
    </Modal>

    <Modal
        v-model="stopLimitModal"
        :class-name="isLocaleLTR ?'custom-modal ltr':'custom-modal rtl'"
        footer-hide
    >
      <p slot="header" class="custom-modal-header">
        <span class="font-Regular-16">{{ $t('exchange.orderDetails') }}</span>
      </p>
      <div class="font-Regular-14 custom-modal-body pb-5">
        <ul v-if="Object.entries(preconditionOrderDetails).length > 0" class="preconditionDetailList">
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.type") }}</span>
            <span v-if="preconditionOrderDetails.precondition[0].source === 'STOP_LIMIT_CONDITION'"
                  class="font-Regular-12">
              {{ $t("exchange.stopLimit") }}
            </span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.pairCoin") }}</span>
            <span class="font-Regular-12">{{ preconditionOrderDetails.precondition[0].symbol }}</span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.direction") }}</span>
            <span
                :class="['font-Regular-12', preconditionOrderDetails.precondition[0].direction  === 'BUY' ? 'text-green' : 'text-red']">
                        {{
                preconditionOrderDetails.precondition[0].direction === 'BUY' ? $t("exchange.buy") : $t("exchange.sell")
              }}
                    </span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.amount") }}</span>
            <span class="font-Regular-12">{{
                preconditionOrderDetails.precondition[0].amount
              }} {{ preconditionOrderDetails.order.coinSymbol }}</span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.activationPrice") }}</span>
            <div class="font-Regular-12">
              {{ preconditionOrderDetails.precondition[0].activationPrice }}
              <span class="font-Regular-16">
                {{
                  preconditionOrderDetails.precondition[0].momentMarketPrice >= preconditionOrderDetails.precondition[0].activationPrice ? "=>" : "=<"
                }}
              </span>
            </div>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.price") }}</span>
            <span class="font-Regular-12">{{ preconditionOrderDetails.precondition[0].orderPrice }}</span>
          </li>
          <li class="d-flex justify-between mb-5">
            <span class="font-Regular-12 precondition-detail-title">{{ $t("exchange.description") }}</span>
            <span class="font-Regular-12">{{ preconditionOrderDetails.precondition[0].errorMessage }}</span>
          </li>
        </ul>
        <div v-if="Object.entries(preconditionOrderDetails).length > 0" class="table-responsive-wrapper">
          <Table :columns="orderStopLimitDetailsColumns" :data="preconditionOrderDetails.order.detail"
                 class="table-responsive-wrapper-in"
                 max-height="250"></Table>
        </div>
      </div>
    </Modal>

    <Modal
        v-model="preconditionCancelModal"
        class-name="custom-modal"
        width="400"
        :dir="isLocaleLTR ? 'ltr' : 'rtl'"
    >
      <div slot="header" class="custom-modal-header font-Regular-16 text-center">
        {{ $t('exchange.cancelOrder') }}
      </div>
      <div class="font-Regular-14 custom-modal-body text-start" :style="isLocaleLTR?'padding-inline: 15px':''">
        <p>{{ $t('exchange.cancelOrderConfirmation') }}</p>
      </div>
      <div slot="footer" class="custom-modal-footer">
        <Button class="font-Medium-14" type="warning" @click="cancelPreconditionOrder(preconditionCanceledId)">
          {{ $t('exchange.confirm') }}
        </Button>
        <Button class="font-Medium-14 ms-2" @click="preconditionCancelModal = false">
          {{ $t('exchange.cancel') }}
        </Button>
      </div>
    </Modal>
  </section>
</template>

<script>
import Loader from "@/components/UI/LoadingComp"
import BaseIcon from "@/components/UI/BaseIcon";
import {mapActions, mapGetters} from "vuex";
// import {isLocaleEn, isLocaleLTR} from "@/utiles/languages";

export default {
  name: "OrdersList",
  components: {
    Loader,
    BaseIcon
  },
  props: {
    openOrdersList: {
      type: Array,
      required: true
    },
    preconditionsList: {
      type: Array,
      required: true
    },
    openOrdersLoading: {
      type: Boolean,
      required: true
    },
    preconditionsLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tabName: "current",
      cancelOrderModal: false,
      cancelOrderId: "",
      detailsBtnLoading: null,
      orderDetails: {},
      orderDetailsModal: false,
      preconditionOrderDetails: [],
      stopLimitModal: false,
      preconditionCanceledId: "",
      preconditionCancelModal: false,
      orderDetailColumns: [
        {
          title: this.$t("exchange.num"),
          key: "amount",
          align: "center",
          minWidth: 100,
        },
        {
          title: this.$t("exchange.price"),
          key: "price",
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("exchange.time"),
          key: "time",
          align: "center",
          minWidth: 160,
          render: (h, params) => {
            return h(
                "span",
                {},
                this.$options.filters.dateFormat(params.row.time)
            );
          },
        },
        {
          title: this.$t("exchange.turnover"),
          key: "turnover",
          align: "center",
          minWidth: 120,
        },
      ],
      orderStopLimitDetailsColumns: [
        {
          title: this.$t("exchange.num"),
          key: "amount",
          align: "center",
          width: 100,
        },
        {
          title: this.$t("exchange.price"),
          key: "price",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("exchange.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.$options.filters.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("exchange.turnover"),
          key: "turnover",
          align: "center",
          width: 120,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
    isSmallScreen() {
      return window.innerWidth <= 375;
    },
  },
  methods: {
    // isLocaleLTR,
    // isLocaleEn,
    ...mapActions({
      cancelPreconditionOrderService:
          "exchange/CANCEL_PRECONDITION_ORDER_SERVICE",
      orderCancel: "exchange/CANCEL_ORDER_SERVICE",
      getPreconditionOrderDetailByOrderID:
          "exchange/GET_PRECONDITION_ORDER_DETAIL_BY_ORDER_ID_SERVICE",
      getExchangeOrderDetail: "exchange/GET_EXCHANGE_ORDER_DETAIL_SERVICE",
    }),
    changeTab(name) {
      this.tabName = name;
      this.$emit("getOrders", name)
    },
    cancelOrder(orderId) {
      if (this.detailsBtnLoading !== null) return;
      this.cancelOrderModal = true;
      this.cancelOrderId = orderId;
    },
    cancel() {
      this.orderCancel(this.cancelOrderId)
          .then(() => {
            this.$emit("getOrders", this.tabName);
            this.$toast.success(this.$t("dashboard.orderCanceled"));
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.cancelOrderId = "";
            this.cancelOrderModal = false;
          })
    },
    getDetails(data) {
      if (this.detailsBtnLoading !== null) return;
      if (
          data.source === "MANUAL_ORDER" ||
          data.source === "OCO_ORDER"
      ) {
        this.getOrderDetails(data.orderId);
      } else if (
          data.source === "STOP_LIMIT_ORDER" ||
          data.source === "OCO_STOP_LIMIT_ORDER"
      ) {
        this.getStopLimitOrderDetails(data.orderId);
      }
    },
    getOrderDetails(orderId) {
      this.detailsBtnLoading = orderId;
      this.getExchangeOrderDetail(orderId).then((response) => {
        this.orderDetails = response.data.data;
        this.orderDetailsModal = true;
      })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.detailsBtnLoading = null;
          })
    },
    getStopLimitOrderDetails(orderId) {
      this.detailsBtnLoading = orderId;
      this.getPreconditionOrderDetailByOrderID({id: orderId}).then((response) => {
        this.preconditionOrderDetails = response.data.data;
        this.stopLimitModal = true;
      })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.detailsBtnLoading = null;
          })
    },
    cancelPrecondition(orderId) {
      this.preconditionCancelModal = true;
      this.preconditionCanceledId = orderId;
    },
    cancelPreconditionOrder() {
      this.cancelPreconditionOrderService(this.preconditionCanceledId)
          .then(() => {
            this.$emit("getOrders", this.tabName);
            this.$toast.success(this.$t("dashboard.orderCanceled"));
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.preconditionCanceledId = "";
            this.preconditionCancelModal = false;
          })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "OrdersList.scss";
</style>